import {
  addDays,
  subDays,
  format,
  isValid,
  parse,
  formatDistance,
  parseISO
} from 'date-fns';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const DISPLAYED_DATE_FORMAT = 'dd-MMM-yyyy';
export const PRICING_DATE_FORMAT = 'dd/MM/yyyy';
export const VERIFICATION_DATE_FORMAT = 'dd MMM yyyy';
export const DAY_DATE_FORMAT = 'd';
export const MONTH_ABBRV_DATE_FORMAT = 'MMM';
export const MONTH_YEAR_ABBRV_DATE_FORMAT = 'MMM yy';
export const YEAR_DATE_FORMAT = 'yyyy';
export const MONTHLY_PAYMENT_DATE_FORMAT = 'MMM yyyy';

export const SHORT_MONTH_DATE_FORMAT = 'PP';
export const LONG_MONTH_DATE_FORMAT = 'PPP';

export const getDateAsUTC = date => {
  let dateInstance = date;
  if (typeof date === 'string') {
    dateInstance = parseISO(date);
  }

  return dateInstance;
};

export const addDaysToDate = (date, days) => addDays(getDateAsUTC(date), days);

export const subtractDaysToDate = (date, days) =>
  subDays(getDateAsUTC(date), days);

export const isDateValid = date => isValid(getDateAsUTC(date));
export const formatDate = (newDate, newFormat = DATE_FORMAT) => {
  if (!isDateValid(newDate)) {
    return '';
  }

  return format(getDateAsUTC(newDate), newFormat);
};

export const formatDisplayDate = date =>
  formatDate(date, DISPLAYED_DATE_FORMAT);
export const formatPricingDate = date => formatDate(date, PRICING_DATE_FORMAT);
export const formatMonthlyPaymentDate = date =>
  formatDate(date, MONTHLY_PAYMENT_DATE_FORMAT);
export const formatVerificationDate = date =>
  formatDate(date, VERIFICATION_DATE_FORMAT);
export const formatMonthAbbrvDate = date =>
  formatDate(date, MONTH_ABBRV_DATE_FORMAT);
export const formatMonthAndYearAbbrvDate = date =>
  formatDate(date, MONTH_YEAR_ABBRV_DATE_FORMAT);
export const formatDateDay = date => formatDate(date, DAY_DATE_FORMAT);

export const formatRelativeDates = (pastDate, futureDate) => {
  const utcPastDate = getDateAsUTC(pastDate);
  const utcFutureDate = getDateAsUTC(futureDate);

  return formatDistance(utcPastDate, utcFutureDate, { addSuffix: true });
};
export const formatReadableDate = date =>
  format(getDateAsUTC(date), SHORT_MONTH_DATE_FORMAT);

export const formatLongReadableDate = date =>
  format(getDateAsUTC(date), LONG_MONTH_DATE_FORMAT);

export const parseDate = (dateString, formatToParse = DATE_FORMAT) =>
  dateString instanceof Date
    ? dateString
    : parse(dateString, formatToParse, new Date());
